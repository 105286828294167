<template>
  <v-dialog
    max-width="500"
    v-model="modal">
    <template v-slot:default="dialog">
      <v-card
        class="rounded-lg d-flex flex-column"
        style="min-height: 300px;">
        <v-card-title
          class="pt-9 px-12">
          <v-row
            class="text-center d-flex justify-start">
            <span
              class="text-h6 font-weight-bold primary--text">
              Detalhes da movimentação
            </span>
            <v-spacer />
            <v-icon
              size="25"
              color="grey"
              @click="close">
              close
            </v-icon>
          </v-row>
        </v-card-title>
        <v-card-text
          class="px-9 pt-5 d-flex flex-column flex-grow-1">
          <p
            class="mb-0 pb-0 text-subtitle-2 font-weight-bold">
            Data/hora da criação da movimentação
          </p>
          <p
            class="pb-0 text-body-2 mb-3">
            {{ formatter.formatDateTimeZoneWithHours(movementDetails.movementDate) }}
            {{ movementDetails.userName ? `por ${movementDetails.userName}` : '' }}
          </p>

          <div
            v-if="Boolean(movementDetails && movementDetails.releasedAt)">
            <p
              class="mb-0 pb-0 text-subtitle-2 font-weight-bold">
              Data/hora da liberação da movimentação
            </p>
            <p
              class="pb-0 text-body-2 mb-3">
              {{ formatter.formatDateTimeZoneWithHours(movementDetails.releasedAt) }}
              {{ movementDetails.releaseUser ? `por ${movementDetails.releaseUser}` : '' }}
            </p>
          </div>

          <div
            v-if="Boolean(movementDetails && movementDetails.sendAt)">
            <p
              class="mb-0 pb-0 text-subtitle-2 font-weight-bold">
              Data/hora de envio para a operadora
            </p>
            <p
              class="pb-0 text-body-2 mb-3">
              {{ formatter.formatDateTimeZoneWithHours(movementDetails.sendAt) }}
              {{ movementDetails.sendUser ? `por ${movementDetails.sendUser}` : '' }}
            </p>
          </div>

          <div
            v-if="Boolean(movementDetails && movementDetails.movementInsuranceCarrierId)">
            <p
              class="mb-0 pb-0 text-subtitle-2 font-weight-bold">
              Nº do lote de envio da operadora
            </p>
            <p
              class="pb-0 text-body-2 mb-3">
              {{ movementDetails.movementInsuranceCarrierId }}
            </p>
          </div>

          <div
            v-if="
              Boolean(movementDetails && movementDetails.dateFinish)
              && ['SUCCESS', 'FINISH_WITH_CRITICAL'].some(status => movementDetails.status === status)">
            <p
              class="mb-0 pb-0 text-subtitle-2 font-weight-bold">
              Data/hora da finalização da movimentação
            </p>
            <p
              class="pb-0 text-body-2 mb-3">
              {{ formatter.formatDateTimeZoneWithHours(movementDetails.dateFinish) }}
              {{ movementDetails.userFinish
                ? `por ${movementDetails.userFinish}`
                  : movementDetails.userForceFinish
                  ? `por ${movementDetails.userForceFinish}`
                : ''
              }}
            </p>
          </div>

          <div
            v-if="Boolean(movementDetails && movementDetails.dateCancellation)">
            <p
              class="mb-0 pb-0 text-subtitle-2 font-weight-bold">
              Data/hora do cancelamento da movimentação
            </p>
            <p
              class="pb-0 text-body-2 mb-3">
              {{ formatter.formatDateTimeZoneWithHours(movementDetails.dateCancellation) }}
              {{ movementDetails.userCancellation ? `por ${movementDetails.userCancellation}` : '' }}
            </p>
          </div>
          <div
            v-if="Boolean(movementDetails && movementDetails.reasonCancellation)">
            <p
              class="mb-0 pb-0 text-subtitle-2 font-weight-bold">
              Motivo do cancelamento
            </p>
            <p
              class="pb-0 text-body-2 mb-3">
              {{ movementDetails.reasonCancellation ? movementDetails.reasonCancellation : '-' }}
            </p>
          </div>
        </v-card-text>
        <v-card-actions
          class="px-9 pb-9">
          <v-row
            no-gutters>
            <v-spacer />
            <v-btn
              class="text-body-2 elevation-0 primary--text"
              height="40"
              color="#EDEFF1"
              @click="close">
              <v-icon
                left>
                fas fa-arrow-left
              </v-icon>
              Fechar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import Formatters from '@/shared/formatters/formatters';
export default {
  name: "MovementDetailModal",

  data: () => ({
    modal: false,
    formatter: null,
    movementDetails: null
  }),

  methods: {
    open(item) {
      this.movementDetails = item;
      this.modal = true;
    },
    close() {
      this.modal = false;
      this.movementDetails = false;
    }
  },

  created() {
    this.formatter = new Formatters();
  },
}
</script>