<template style="background: #E5E5E5">
  <div>
    <v-dialog :value="dialog.show" persistent max-width="1080">
      <v-card class="pa-5 modalManualCriticismTopCenter">
        <v-card-title class="headline pl-3 ml-3 mb-10">
          <v-row>
            <v-col cols="12" style="color: #3b495b; font-weight: bold">Crítica Manual</v-col>
            <v-col cols="12" style="color: #4d596a">
              Nome:
              <span style="font-weight: bold">{{ insuredName }}</span>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="pb-0">
          <v-form v-model="formRules" ref="formRules">
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-autocomplete
                  v-model="manualCriticism.ruleId"
                  label="Regra*"
                  clearable
                  placeholder="Digite aqui"
                  :items="rules"
                  item-text="name"
                  item-value="id"
                  @change="setRuleName()"
                  outlined
                  dense
                  color="textPrimary"
                  item-color="textPrimary"
                  append-icon="fas fa-chevron-down"
                  :rules="manualCriticism.ruleId || manualCriticism.message || manualCriticism.field ? [] : [rule.required]"
                  validate-on-blur
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="manualCriticism.message"
                  label="Mensagem*"
                  placeholder="Digite aqui"
                  color="textPrimary"
                  dense
                  outlined
                  :rules="manualCriticism.ruleId || manualCriticism.message || manualCriticism.field ? [] : [rule.required]"
                  validate-on-blur
                />
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-autocomplete
                  v-model="manualCriticism.field"
                  :items="criticismFields"
                  item-text="label"
                  item-value="value"
                  hide-selected
                  clearable
                  dense
                  :loading="!criticismFields.length"
                  :disabled="!criticismFields.length"
                  label="Campo criticado*"
                  placeholder="Selecione"
                  persistent-hint
                  outlined
                  :rules="manualCriticism.ruleId || manualCriticism.message || manualCriticism.field ? [] : [rule.required]"
                  validate-on-blur
                  @change="manualCriticism.fieldValue = null"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-if="manualCriticism.field && manualCriticism.field.includes('date')"
                  v-model="manualCriticism.fieldValue"
                  label="Valor criticado"
                  placeholder="Digite aqui"
                  color="textPrimary"
                  dense
                  outlined
                  v-mask="'##/##/####'"
                  :rules="manualCriticism.fieldValue ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid] : []"
                />
                <v-text-field
                  v-else
                  v-model="manualCriticism.fieldValue"
                  label="Valor criticado"
                  placeholder="Digite aqui"
                  color="textPrimary"
                  dense
                  outlined
                />
              </v-col>
            </v-row>
            <v-col class="pt-0 pr-0" align="end">
              <v-btn
                v-if="isEdit"
                class="mr-4"
                color="#3B495B"
                outlined
                @click="cleanFields()"
              >
                <v-icon left dark>fas fa-times</v-icon>
                Cancelar
              </v-btn>
              <v-btn color="#3B495B" outlined :disabled="!criticismFields.length" @click="setCriticism()">
                <v-icon left dark>fas fa-save</v-icon>
                {{ isEdit ? 'Salvar' : 'Adicionar' }}
              </v-btn>
            </v-col>
          </v-form>
        </v-card-text>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="manualCriticismList"
            :items-per-page="10"
            :loading="isLoadingTable"
            item-key="id"
            class="elevation-1"
          >
            <template v-slot:[`item.rule`]="{ item }">
              {{ item.rule ? item.rule : "Sem mensagem" }}
            </template>
            <template v-slot:[`item.message`]="{ item }">
              {{ item.message ? item.message : "Sem mensagem" }}
            </template>
            <template v-slot:[`item.field`]="{ item }">
              {{ item.field ? formatter.formatToTitleCase(item.field) : "Sem mensagem" }}
            </template>
            <template v-slot:[`item.fieldValue`]="{ item }">
              {{ item.fieldValue ? checkFormatValue(item) : "Sem mensagem" }}
            </template>
            <template v-slot:[`item.attachments`]="{ item }">
              <v-btn small icon color="textPrimary" @click="editItem(item)">
                <v-icon>fas fa-edit</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-row class="mt-6">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-6 pa-7 btn-dialog"
              color="#3B495B"
              style="color: white"
              @click="onClickCloseModal()"
            >
              OK
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import MovementInsuranceCarrierReturnService from '@/services-http/sdi/MovementInsuranceCarrierReturnService';
import DataSourceService from '@/services-http/sdi/DataSourceService';
import lodash from 'lodash';

export default {
  name: 'ManualCriticismDialog',
  data: () => ({
    headers: [
      {
        text: 'Regra',
        align: 'start',
        value: 'rule',
      },
      {
        text: 'Mensagem',
        align: 'center',
        value: 'message',
      },
      {
        text: 'Campo criticado',
        align: 'center',
        value: 'field',
      },
      {
        text: 'Valor criticado',
        align: 'center',
        value: 'fieldValue',
      },
      {
        text: 'Ação',
        align: 'center',
        value: 'attachments',
      },
    ],
    manualCriticism: {
      id: null,
      ruleId: 0,
      rule: null,
      cardNumber: null,
      movementRecordId: 0,
      returnType: 'FAIL',
      message: null,
      criticismType: null,
      field: null,
      fieldValue: null,
      movementInsuranceCarrierId: 0,
    },
    dialog: { show: false },
    snackbar: {
      show: false,
      text: '',
      type: '',
    },
    formRules: false,
    isEdit: false,
    manualCriticismCurrent: [],
    isLoadingTable: false,
    isModified: false,
    criticismFields: [],
    rules: [],
    manualCriticismList: [],
  }),
  props: {
    show: { type: Boolean },
    movementRecordId: { type: Number },
    movementInsuranceCarrierId: { type: Number },
    insuredName: { type: String },
    propsRules: { type: Array },
    isFreeMovement: { type: Boolean },
  },

  watch: {
    show: {
      async handler(val) {
        this.dialog.show = val;
        if (val) {
          this.manualCriticism.movementRecordId = this.movementRecordId;
          this.manualCriticism.movementInsuranceCarrierId = this.movementInsuranceCarrierId;
          this.isModified = false;
          await this.loadCriticismField();
          await this.loadManualCriticisms();
        }
      },
      deep: true,
      immediate: true,
    },
    propsRules(val) {
      if (val) {
        this.rules = val;
      }
    },
    manualCriticismList(val) {
      if (val) {
        this.manualCriticismCurrent = lodash.cloneDeep(this.manualCriticismList);
      }
    },
  },
  methods: {
    async loadManualCriticisms() {
      this.isLoadingTable = true;
      this.manualCriticismList = [];
      this.movementInsuranceCarrierReturnService.FindByMovementRecordId(this.movementRecordId).then((response) => {
        if (response && response.data && response.data.content.length > 0) {
          const manualCriticismFails = response.data.content.filter((m) => m.returnType === 'FAIL');
          this.manualCriticismList = manualCriticismFails;
          this.isLoadingTable = false;
        }
      }).catch(() => {
        this.isLoadingTable = false;
      }).finally(() => {
        this.isLoadingTable = false;
      });
    },
    async loadCriticismField() {
      await this.dataSourceService.FindAll().then((response) => {
        const fields = response.data.find((item) => item.value === 'movimentacao');
        this.criticismFields = fields.columns;
      });
    },
    setCriticism() {
      if (this.$refs.formRules.validate()) {
        this.checkFieldType();
        const listAux = [];
        listAux.push(this.manualCriticism);
        if (!this.isEdit) {
          this.createCriticism(listAux);
        } else {
          this.updateCriticism(listAux);
        }
        this.cleanFields();
      } else {
        this.customizeSnackbarMessage('error', 'Preencha ao menos um dos campos obrigatórios.');
      }
    },
    createCriticism(listAux) {
      this.movementInsuranceCarrierReturnService.Save(listAux, this.isFreeMovement).then(() => {
        this.loadManualCriticisms();
        this.isModified = true;
      });
    },
    updateCriticism(listAux) {
      if (this.validationManualCriticisms()) {
        this.movementInsuranceCarrierReturnService.Save(listAux, this.isFreeMovement).then(() => {
          this.loadManualCriticisms();
          this.isModified = true;
        });
      } else {
        this.loadManualCriticisms();
      }
    },
    checkFieldType() {
      const formatField = (this.manualCriticism.field.includes('date') && this.manualCriticism.fieldValue) ? this.formatter.formatDateBRtoString(this.manualCriticism.fieldValue) : this.manualCriticism.fieldValue;
      this.manualCriticism.fieldValue = formatField;
    },
    setRuleName() {
      const ruleName = this.rules.find((item) => item.id === this.manualCriticism.ruleId);
      if (ruleName) {
        this.manualCriticism.rule = ruleName.name;
      }
    },
    editItem(item) {
      if (item) {
        this.isEdit = true;
      } else {
        this.isEdit = false;
      }
      this.manualCriticism = {
        id: item.id,
        ruleId: item.ruleId,
        rule: item.rule,
        cardNumber: item.cardNumber,
        movementRecordId: item.movementRecordId,
        returnType: 'FAIL',
        message: item.message,
        criticismType: item.criticismType,
        field: item.field,
        fieldValue: item.field && item.field.includes('date') ? this.formatter.formatDate(item.fieldValue) : item.fieldValue,
        movementInsuranceCarrierId: item.movementInsuranceCarrierId,
      };
    },
    onClickCloseModal() {
      this.$emit('close', this.isModified);
    },
    cleanFields() {
      this.manualCriticism = {
        id: null,
        ruleId: null,
        criticismType: null,
        rule: null,
        cardNumber: null,
        movementRecordId: this.movementRecordId,
        returnType: 'FAIL',
        message: null,
        field: null,
        fieldValue: null,
        movementInsuranceCarrierId: this.movementInsuranceCarrierId,
      };
      this.isEdit = false;
    },
    checkFormatValue(item) {
      if (item && item.field && item.field.includes('date')) {
        return this.formatter.formatDate(item.fieldValue);
      }
      return item.fieldValue;
    },
    validationManualCriticisms() {
      const criticismModified = this.manualCriticismCurrent.find((element) => element.id === this.manualCriticism.id);
      if (criticismModified) {
        if (criticismModified.ruleId !== this.manualCriticism.ruleId || criticismModified.message !== this.manualCriticism.message || criticismModified.field !== this.manualCriticism.field || criticismModified.fieldValue !== this.manualCriticism.fieldValue) {
          return true;
        }
      }
      return false;
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
  },
  created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.movementInsuranceCarrierReturnService = new MovementInsuranceCarrierReturnService();
    this.dataSourceService = new DataSourceService();
    this.dialog.show = this.show;
  },
};
</script>

<style scoped>
.btn-dialog {
  width: 200px;
  font-size: 16px;
}
</style>
